import React from "react"

import { Button } from "../buttons/buttons"

export const ColTextWImage = ({ data }) => {
  return data ? (
    <div className="partner-item">
      <img
        alt="Partner Logo"
        src={data.acf.image.sourceUrl}
        className="img-fluid"
      />
      <div className="partner-item-content">
        <h4 className="h4">{data.acf.title}</h4>
        <p>{data.acf.text}</p>
        {data.acf.buttons.length > 0 && (
          <Button
            link={data.acf.buttons.length > 0 && data.acf.buttons[0].link.url}
            variant="link"
          >
            {data.acf.buttons.length > 0 && data.acf.buttons[0].text}
          </Button>
        )}
      </div>
    </div>
  ) : (
    <div></div>
  )
}
